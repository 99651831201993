import styled from "styled-components"
import theme from "../../../theme"

const CircleStyled = styled.div`
  display: inline-flex;
  border: solid 4px #333333;
  border-radius: 50%;
  padding: 15px;
  vertical-align: middle;
  text-align: center;
  /* width: auto;
  height: 0
  padding-bottom: 100%; */
`

export default CircleStyled
