import styled from "styled-components"

const CircleWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  text-align: center;
`

export default CircleWrapper
