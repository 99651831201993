import React from "react"
import PropTypes from "prop-types"

import CircleWrapper from "./styled/CircleWrapper"
import CircleStyled from "./styled/CircleStyled"
import CirclePadding from "./styled/CirclePadding"

const Circle = props => {
  const { children } = props
  return (
    <CircleWrapper>
      <CircleStyled>
        <CirclePadding {...props}>{children}</CirclePadding>
      </CircleStyled>
    </CircleWrapper>
  )
}

export default Circle

Circle.propTypes = {
  children: PropTypes.node,
}
