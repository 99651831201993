import styled from "styled-components"

const CirclePadding = styled.div`
  display: inline-table;
  text-align: center;
  padding-top: ${props => props.py + "px" || "0"};
  padding-bottom: ${props => props.py + "px" || "0"};
  :after {
    content: "";
    padding-left: ${props => props.px + "px" || "0"};
  }
  :before {
    content: "";
    padding-right: ${props => props.px + "px" || "0"};
  }
`

export default CirclePadding
